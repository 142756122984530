<template>
  <div>
    <!-- icon row -->
    <div class="row routing-top-space routing-bottom-space">
      <div class="col-12">
        <span class="text-center">
          <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 100 125"
            style="enable-background:new 0 0 1024 1280;"
            xml:space="preserve"
            height="90"
            width="90"
            fill="#ffffff"
          >
            <g>
              <g>
                <path
                  d="M50.2,99.7c-27,0-49-21.9-49-48.9S23.2,2,50.2,2s49,21.9,49,48.9S77.2,99.7,50.2,99.7z M50.2,7.1
			C26,7.1,6.4,26.7,6.4,50.8S26,94.6,50.2,94.6S94.1,75,94.1,50.8S74.4,7.1,50.2,7.1z"
                />
              </g>
              <g>
                <polygon points="47.4,69.2 29,52.3 32.5,48.5 46.6,61.4 67,31.7 71.3,34.7 		" />
              </g>
            </g>
          </svg>
        </span>
      </div>
    </div>
    <!-- success text row -->
    <div class="row row-space">
      <div class="col-12">
        <span class="success-text">Success!</span>
      </div>
    </div>
    <!-- para 1 -->
    <div class="row row-space">
      <div class="col-12">
        <span class="success-sub-text">
          You've connected your
          <br />bank account
        </span>
      </div>
    </div>
    <!-- if mobile device -->
    <div v-if="mobile" class="button-space">
      <!-- para 2 -->
      <div class="row success-btn-space">
        <div class="col-12">
          <span class="bank-linking-text">You can now sign into your CanPay account</span>
        </div>
      </div>
      <!-- btn row -->
      <div class="row">
        <div class="col-12 col-pad">
          <button type="button" class="btn-login" @click="signIn">Sign In</button>
        </div>
      </div>
    </div>
    <!-- for desktop -->
    <div v-else class="button-space">
      <!-- para 2 -->
      <div class="row success-btn-space">
        <div class="col-12">
          <span
            class="bank-linking-text"
          >To start using CanPay, sign into your account on your mobile phone</span>
        </div>
      </div>
      <!-- btn row -->
      <div class="row row-space">
        <div class="col-12 col-pad">
          <button type="button" class="btn-login" @click="textLink">Text Me The Link</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../api/registration.js";
import { isMobile } from "mobile-device-detect";
export default {
  name: "Success",
  data() {
    return {
      mobile: false
    };
  },
  props: {
    sessionId: ""
  },
  created() {
    if (isMobile) {
      this.mobile = true;
    }
  },
  methods: {
    signIn() {
      if (!isMobile) {
        alert(
          "To start using CanPay, sign into your account on your mobile phone"
        );
        return false;
      }
      this.$router.push("/login");
      // if (localStorage.getItem("session_exists_phone") == null) {
      //   this.$router.push("/login");
      // } else {
      //   //login into CanPay
      //   var request = {
      //     phone: localStorage.getItem("session_exists_phone"),
      //     login_without_pin: true
      //   };
      //   localStorage.removeItem("session_exists_phone");
      //   api
      //     .loginUser(request)
      //     .then(response => {
      //       if (response.code == 200) {
      //         localStorage.removeItem("consumer_token");
      //         localStorage.removeItem("consumer_login_response");
      //         localStorage.setItem("consumer_token", response.data.token);
      //         localStorage.setItem(
      //           "consumer_login_response",
      //           JSON.stringify(response.data)
      //         );
      //         this.$router.push("/pay");
      //       } else {
      //         alert(response.message);
      //       }
      //     })
      //     .catch(function(error) {
      //       console.log(error);
      //     });
      // }
    },
    textLink() {
      var request = {
        id: localStorage.getItem("user_id"),
        sessionId: this.sessionId
      };
      localStorage.removeItem("user_id");
      api
        .sendLink(request)
        .then(response => {
          alert(response.message);
          if (response.code == 401) {
            this.isLoading = false;
            self.$router.go();
          }
          if (response.data != null) {
            self.show = response.data;
          }
          this.$router.push("/login");
        })
        .catch(err => {
          console.log("error", err);
          this.isLoading = false;
          alert(err);
        });
    }
  }
};
</script>